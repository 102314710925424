//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("partner/getWithdrawals", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  data() {
    return {
      statusValues: {
        created: "装饰",
        complete: "完全的"
      },
      methodValues: {
        bank_invoice: "至往来账户",
        card: "至银行卡"
        // crypto: "加密货币(USDC)",
      }
    };
  },

  computed: {
    columns() {
      return [{
        title: "日期",
        show: true,
        name: "createdAt",
        width: 100,
        type: "date"
      }, {
        title: "和",
        show: true,
        name: "amount",
        width: 100,
        filter: "numberRange",
        type: "money"
      }, {
        title: "商店状态",
        show: true,
        name: "status",
        width: 100,
        filter: "select",
        type: "slot",
        values: this.statusValues
      }, {
        title: "方法",
        show: true,
        name: "method",
        width: 100,
        filter: "select",
        type: "slot",
        values: this.methodValues
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};