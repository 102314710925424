//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      method: null,
      methods: [{
        text: "微信钱包",
        value: "card",
        description: " "
      }
      /* {
          text: "加密货币(USDC)",
          value: "crypto",
          description: "对于个人 - 佣金 10%",
      }, */]
    };
  },

  computed: {},
  methods: {
    async submit() {
      if (!this.method) {
        return;
      }
      this.$emit("complete", await this.$modal.process(() => import("@/components/Partner/ModalWithdrawal"), {
        method: this.method
      }));
    }
  }
};