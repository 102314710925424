//
//
//
//
//
//

import PartnerPage from "@/components/Partner/Page";
export default {
  components: {
    PartnerPage
  },
  meta: {
    title: "套餐与支付"
  }
};