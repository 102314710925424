//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PartnerSummary from "./Summary";
import PartnerWithdrawal from "./Withdrawal";
import PartnerOverview from "./Overview";
import RangeCalendar from "@/components/RangeCalendar";
import { dateRangeFromNow, today } from "@/utils/dates";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import PartnerVisitsTable from "@/components/Partner/tables/VisitsTable.vue";
import PartnerIncomesTable from "@/components/Partner/tables/IncomesTable.vue";
import PartnerWithdrawalsTable from "@/components/Partner/tables/WithdrawalsTable.vue";
import UiButton from "@/components/common/ui/Button";
const tableComponents = {
  visits: PartnerVisitsTable,
  incomes: PartnerIncomesTable,
  withdrawals: PartnerWithdrawalsTable
};
export default {
  components: {
    PartnerSummary,
    PartnerWithdrawal,
    PartnerOverview,
    RangeCalendar,
    AppTabs,
    DataTableActions
  },
  created() {
    return Promise.all([this.$store.dispatch("user/getUser"), this.load()]);
  },
  mounted() {},
  data() {
    return {
      components: {
        UiButton
      },
      name: "",
      tag: "",
      tags: [],
      calendar: dateRangeFromNow(7),
      calendarBoundaries: [null, today()],
      tabs_tables: [
      /* {
          id: "visits",
          title: "转换和注册",
      }, */
      {
        id: "incomes",
        title: "应计费用"
      }, {
        id: "withdrawals",
        title: "提取资金"
      }],
      tabs_tables_model: null,
      state: null,
      clipboard: null,
      refreshTables: 0
    };
  },
  computed: {
    ready() {
      return !!this.state;
    },
    user() {
      return this.$store.state.user.user;
    },
    partnerLink() {
      if (!this.user.user_id) {
        return null;
      }
      return `https://salesfinder.cn/?invite=${this.user.user_id}`;
    },
    reportSettings() {
      return {
        date: this.calendar[0],
        date2: this.calendar[1]
      };
    },
    fileName() {
      return `联属计划 ${this.tabs_tables_model.title}`;
      // return `联属网络营销计划 ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    }
  },

  methods: {
    async load() {
      this.state = await this.$store.dispatch("partner/state");
    },
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    async onWithdrawalCreated() {
      this.refreshTables++;
      await this.load();
    }
  }
};